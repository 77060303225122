import axios from "axios";
import { baseUrl, headers } from "../config/env";
/**
 * create(POST) / fetch(GET) / remove(DELETE) / update (PUT)
 *
 */
export function login(data) {
  return axios.post(baseUrl + "/admin/login", data, { headers });
}

export function fetchProfile() {
  return axios.get(baseUrl + `/accounts/profile`, { headers });
}

export function deleteUser(userId) {
  return axios.delete(baseUrl + `/admin/user/${userId}`, {
    headers,
  });
}
