import { fetchProfile } from "@/api/index";
import { fetchClubList } from "@/api/admin";
import { fetchCertifyList } from "@/api/admin";

const actions = {
  /**
   * LOGIN
   */
  LOGIN({ commit }, data) {
    commit("LOGIN", data);
    commit("SET_USER_INFO", data);
  },
  /*
   * LOGOUT
   */
  LOGOUT({ commit }) {
    commit("LOGOUT");
  },

  /**
   * fetch User 정보
   */
  getAccountInfo({ commit, dispatch }) {
    fetchProfile().then((res) => {
      if (res.data.status == 200 && res.data.data) {
        let data = {
          nickname: res.data.data.nickname,
          avatar: res.data.data.avatar,
          _id: res.data.data._id,
          id: res.data.data.id,
          isAdmin: res.data.data.isAdmin,
          mobileNo: res.data.data.mobileNo,
          username: res.data.data.username,
          userType: res.data.data.userType,
          status: res.data.data.status, //회원 가입 처리 상태
        };
        commit("SET_USER_INFO", data);
      } else {
        dispatch("LOGOUT");
      }
    });
  },
  getClubRequestCount({ commit }) {
    let params = {
      status: "REQUEST",
    };
    fetchClubList(params).then((res) => {
      if (res.data.status == 200) {
        commit("SET_CLUB_REQUEST_COUNT", res.data.total);
      } else {
        let message = res.data.message;
        alert(message);
      }
    });
  },
  getCertifyRequestCount({ commit }) {
    let params = {
      status: "REQUEST",
    };
    fetchCertifyList(params).then((res) => {
      if (res.data.status == 200) {
        commit("SET_CERTIFY_REQUEST_COUNT", res.data.total);
      } else {
        let message = res.data.message;
        alert(message);
      }
    });
  },
  SET_NAVBAR({ commit }, data) {
    commit("SET_NAVBAR", data);
  },
  SET_NAVBAR_VISIBLE({ commit }, data) {
    commit("SET_NAVBAR_VISIBLE", data);
  },
  SET_FOOTER({ commit }, data) {
    commit("SET_FOOTER", data);
  },
  SET_USER_INFO({ commit }, data) {
    commit("SET_USER_INFO", data);
  },
  SET_CLUB_REQUEST_COUNT({ commit }, data) {
    commit("SET_CLUB_REQUEST_COUNT", data);
  },
};

export default actions;
