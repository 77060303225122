import axios from "axios";
import { baseUrl, headers } from "../config/env";

// 대시보드
export function fetchDashboard(params) {
  return axios.get(baseUrl + `/admin/dashboard`, { params, headers });
}
export function fetchAvg(params) {
  return axios.get(baseUrl + `/admin/dashboard/avg`, { params, headers });
}

// 회원
export function fetchUserList(params) {
  return axios.get(baseUrl + `/admin/user/list`, { params, headers });
}

export function fetchUserDetails(id) {
  return axios.get(baseUrl + `/admin/user/${id}`, { headers });
}
export function updateUserDetails(data) {
  return axios.put(baseUrl + `/admin/user`, data, { headers });
}

export function removeUser(data) {
  return axios.post(baseUrl + `/admin/withdraw`, data, { headers });
}

// 진단 이력
export function fetchDiagnosisList(params) {
  return axios.get(baseUrl + `/admin/predict/list`, { params, headers });
}
// export function fetchDiagnosisDetails(id) {
//   return axios.get(baseUrl + `/admin/diagnosis/${id}`, { headers });
// }
// export function createDiagnosis(data) {
//   return axios.post(baseUrl + `/admin/diagnosis`, data, { headers });
// }
export function updateDiagnosis(id, data) {
  return axios.put(baseUrl + `/admin/diagnosis/${id}`, data, { headers });
}
export function removeDiagnosis(id) {
  return axios.delete(baseUrl + `/admin/diagnosis/${id}`, { headers });
}

// 공지사항
export function createNotice(data) {
  return axios.post(baseUrl + `/admin/notice`, data, { headers });
}
export function updateNotice(data) {
  return axios.put(baseUrl + `/admin/notice`, data, { headers });
}
export function removeNotice(id) {
  return axios.delete(baseUrl + `/admin/notice/${id}`, { headers });
}
export function fetchNoticeList(params) {
  return axios.get(baseUrl + `/admin/notice/list`, { params, headers });
}
export function fetchNotice(id) {
  return axios.get(baseUrl + `/admin/notice/${id}`, { headers });
}

export function fetchAnalytics(params) {
  return axios.get(baseUrl + `/admin/analytics/user`, { params, headers });
}
