export const baseUrl = "http://localhost:5050";
// export const baseUrl = "http://13.209.88.139:5050";
// export const baseUrl = "http://api.greenlens.kr";
export const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "X-Content-Type-Options": "nosniff",
  authorization: localStorage.getItem("accesstoken"),
};
