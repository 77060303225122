const state = {
  id: null,
  _id: null,
  status: "",
  mobileNo: "",
  nickname: "",
  username: "",
  userType: "",
  avatar: "",
  navbarState: "0",
  isLogin: false,
  isAdmin: false,
  clubRequestCount: "",
  certifyRequestCount: "",
};
export default state;
